.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2vw;
}

@media screen and (min-width: 1900px) {
  .App {
    font-size: 1.2rem;
  }

  .App p {
    margin: 0.5vw 0vw;
  }
}

@media screen and (max-width: 600px) {
  .App {
    font-size: 3vw;
  }
}

h1 {
  margin: 0;
}

h2, h3{
  margin: 1vw 0vw;
}

p {
  margin: 1vw 0vw;
}

#header-container {
  background-image: url("./images/header-image.jpg");
  width: 100%;
  max-height: 450px;
}

header {
  text-align: center;
}

header img {
  max-width: 20%;
  max-height: 22rem;
  border-radius: 50%;

  padding: 1vh 0vw;
}

header h1 {
  padding: 0vh 0vw 1vh 0vw;
  font-size: 3rem;

  color: white;
  -webkit-text-stroke-width: 0.2vh;
  -webkit-text-stroke-color: rgb(13, 48, 80);
}

@media screen and (max-width: 844px) {
  #header-container {
    background-size: cover;
  }
  header h1 {
    font-size: 6vw
  }
}

nav {
  width: 100%;
  height: 3rem;
  overflow: hidden;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;

  background:
  radial-gradient(rgb(206, 206, 206) 2%, transparent 10%) 0 0,
  radial-gradient(rgb(44, 165, 94) 2%, transparent 10%) 8px 8px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-size:6px 6px;
  background-color:rgb(22, 92, 158);
}

.sticky {
  position: fixed;
  top: 0;
}

.sticky + #page-background {
  padding-top: 3rem;
}

#nav-logo {
  float: left;
  height: 3rem;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding: 1rem 0vw;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 1rem 2vw;
}

nav a:hover {
  background: white;
  color: rgb(22, 92, 158);
}

@media screen and (max-width: 600px) {
  nav {
    min-height: 3rem;
    font-size: 0.8rem;
    text-align: unset;

    background:
    radial-gradient(rgb(206, 206, 206) 2%, transparent 10%) 0 0,
    radial-gradient(rgb(44, 165, 94) 2%, transparent 10%) 8px 8px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
    background-size:5px 5px;
    background-color:rgb(22, 92, 158);
  }

  nav li {
    padding: 1rem 2vw 1rem 0vw;
  }

  nav a {
    padding: 1.05rem 2vw;
  }
}

@media screen and (max-width: 400px) {
  nav {
    font-size: 0.50rem;
  }

  nav a {
    padding: 1.15rem 2vw;
  }
}

#page-background {
  padding: 0vw 10vw;

  background:
  radial-gradient(rgb(206, 206, 206) 2%, transparent 10%) 0 0,
  radial-gradient(rgb(44, 165, 94) 2%, transparent 10%) 8px 8px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color:rgb(255, 255, 255);
  background-size:12px 12px;
}

@media screen and (max-width: 600px) {
  #page-background {
    padding: 0vw 3rem;
  }
}

#page-wrapper {
  background: rgb(255, 255, 255);
}

#page-container {
  background: rgb(201, 227, 252);
  background: rgba(22, 92, 158, 0.25);
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.page-component {
  border-bottom: 1px solid black;
}

#page-body {
  padding: 1vw 3vw;
  color: black;
}

#page-body h1 {
  text-align: center;
}

#page-body p {
  text-indent: 2em;
}

#page-body img {
  max-width: 40%;
}

@media screen and (min-width: 1900px) {
  #page-body img {
    max-width: 20%;
  }
}

@media screen and (max-width: 600px) {
  #page-body img {
    max-width: 50%;
  }
}

#education-container ul {
  margin: 0vw 0vw;
  padding: 0vw 0vw 0vw 3vw;
}

#education-container p {
  text-indent: 0em;
}

#lafayette-img {
  float: right;
  margin: 0vw 0vw 1vw 1vw;
}

.skills-list {
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid black;
  border-radius: 2vw;

  margin: 1vw 0vw;
}

#skills-container h2 {
  margin: 0.5vw 0vw;
}

#skills-container div {
  float: left;
  margin: 1vw 1vw;
  width: 45%;

  text-align: center;
}

#skills-container ul {
  margin: 0vw;
  padding: 0vw 0vw;
  list-style-type: none;
}

#skills-container li {
  padding: 0.4vw 0vw;
}

#additional-skills-info h2 {
  margin-bottom: 0vw;
}

#additional-skills-info ul {
  margin: 0vw 0vw;
  padding: 0vw 0vw 0vw 3vw;
}

#noteworthy-courses li {
  padding: 0.8vw 0vw;
}

@media screen and (min-width: 1900px) {
  #skills-container h2 {
    margin: 0.2vw 0vw;
  }

  #skills-container li {
    padding: 0.2vw 0vw;
  }

  #noteworthy-courses li {
    padding: 0.4vw 0vw;
  }
}

@media screen and (max-width: 600px) {
  .skills-list h2 {
    font-size: 0.8rem;
  }
}

#projects-container {
  margin: 0vw 2vw;
}

#projects-container h3, h4 {
  margin: 0.4vw 0vw;
}

#projects-container p {
  text-indent: 0em;
}

.project-container {
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.5);
  margin: 1.5vw 0vw;
  padding: 1vw 0vw;
}

.project {
  margin: 0vw 1vw;
}

.project ul {
  margin: 0vw;
}

.project a {
  text-decoration: none;
}

.interest-container img {
  margin: 0vw 0vw 1vw 1vw;
}

#league-finals-img {
  float: right;
}

#meiji-jingu-img {
  float: left;
  margin: 0px 1vw 1vw 0px;
}

#contact-container {
  text-align: center;
}

#contact-img {
  float: right;
  max-width: 40%;
  margin: 0vw 0vw 1vw 1vw;
}

#contact-container a {
  text-decoration: none;
  color: black;
}

#contact-container a:hover {
  color: rgb(22, 92, 158);
}

@media screen and (max-width: 600px) {
  #contact-img {
    float: none;
    margin: 0vw 18vw;
  }

  #contact-container h2 {
    font-size: 0.8rem;
  }
}